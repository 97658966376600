import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Dropdown, Icon, IconButton } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import "./RegulationPageHeaderButtons.scss";
import { DotsIcon, LookIcon } from "../../../../../../constants/icon";
import { RegulationContext } from "../../../../../../contexts/RegulationPage/RegulationContext";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "../../../../../../hooks";
import { observer } from "mobx-react-lite";

export const RegulationPageHeaderButtons = observer(() => {
  const { isLoadingButton, handleSave, setIsOpenAccessSettingsDialog } = useContext(RegulationContext);

  const navigate = useNavigate();

  const { regulationStore } = useRootStore();


  const { t } = useTranslation();
  const [isOpen, setIsOpen] =useState<boolean>(false);
  const handleIsOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleCancel = useCallback(() => {
    console.log("regulationStore.getParentId", regulationStore.getParentId);
    if (regulationStore.getParentId !== -1) {
      navigate(`/policy/topics/${regulationStore.getParentId}`);
    } else {
      navigate("/policy");
    }
    localStorage.removeItem("create-regulation-editor");
  }, [regulationStore.getParentId]);
  const handleOpenAccessSettingsDialog = useCallback(() => setIsOpenAccessSettingsDialog(true), []);


  return (
    <div className="regulation-page-header-buttons">
      <Button
        loading={isLoadingButton}
        onClick={handleSave}
        variant="filled"
        className="button-icon_16"
      >
        {t("ui:button.save")}
      </Button>
      <Button
        onClick={handleCancel}
        variant="default"
        className="button-icon_16"
      >
        {t("ui:button.cancel")}
      </Button>
      {/*<IconButton*/}
      {/*  onClick={handleOpenAccessSettingsDialog}*/}
      {/*  icon={<Icon component={() => (<LookIcon/>)} />}*/}
      {/*  isShowBorder={true}*/}
      {/*/>*/}
      <Dropdown
        open={isOpen}
        onOpenChange={handleIsOpenChange}
        placement={"bottom"}
        rootClassName={"regulation-editor-toolbar-action-drop-down"}
        trigger={["click"]}
        dropdownRender={() => {
          return (
            <div
              className="regulation-editor-toolbar-action"
            >
              <div
                className="regulation-editor-toolbar-action-item"
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  { t("ui:regulation_actions.added_to_section.title") }
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  { t("ui:regulation_actions.added_to_section.text") }
                </div>
              </div>
              {/*<div className="regulation-editor-toolbar-action-item">*/}
              {/*  <div className="regulation-editor-toolbar-action-item__title">*/}
              {/*    История регламента*/}
              {/*  </div>*/}
              {/*  <div className="regulation-editor-toolbar-action-item__description">*/}
              {/*    В этом разделе можно увидеть все изменения и даты обновлений*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="regulation-editor-toolbar-action-item">
                <div className="regulation-editor-toolbar-action-item__title">
                  { t("ui:regulation_actions.delete.title") }
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  { t("ui:regulation_actions.delete.text") }
                </div>

              </div>
            </div>
          );
        }}
      >
        <IconButton
          onClick={() => handleIsOpenChange(true)}
          icon={<Icon component={() => (<DotsIcon />)} />}
          isShowBorder={true}
        />
      </Dropdown>
    </div>
  )
});