import React from "react";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { UsersIcon } from "../../../../../../constants/icon";

export const AssignStudyButton: React.FC<IRegulationIcons> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(`ui:tooltip.${props.disabled ? "assign_study_without_topic" : "assign_study"}`)}>
      <IconButton
        className="regulation-button-icons"
        disabled={props.disabled}
        onClick={props.onClick}
        isShowBorder={true}
      >
        <UsersIcon/>
      </IconButton>
    </Tooltip>
  );
};
