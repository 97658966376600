
//
// Bb1Economy: Эконом BB1,
// Bb1Business: Бизнес BB1,
// Bb1Premium: Премиум BB1,
// Bb2Economy: Эконом BB2,
// Bb2Business: Бизнес BB2,
// Bb2Premium: Премиум BB2,
// Resident: Резидент,
// Vip: VIP,
// Demo: Демо,
// PlatinumResident: Платиновый резидент,
// Custom: Индивидуальные условия

import { TariffType } from "../api/models/CompanyForAdminDto";
import { ISelectItem } from "../components/uiKit/select/SelectView";

export const TARIFF_LIST: ISelectItem[] = [
  {
    id: 1,
    value: TariffType.Bb1Economy,
    text: "Эконом BB1"
  },
  {
    id: 1,
    value: TariffType.Bb1Business,
    text: "Бизнес BB1"
  },
  {
    id: 1,
    value: TariffType.Bb1Premium,
    text: "Премиум BB1"
  },
  {
    id: 1,
    value: TariffType.Bb2Economy,
    text: "Эконом BB2"
  },
  {
    id: 1,
    value: TariffType.Bb2Business,
    text: "Бизнес BB2"
  },{
    id: 1,
    value: TariffType.Bb2Premium,
    text: "Премиум BB2"
  },
  {
    id: 1,
    value: TariffType.Resident,
    text: "Резидент"
  },
  {
    id: 1,
    value: TariffType.Vip,
    text: "VIP"
  },
  {
    id: 1,
    value: TariffType.Demo,
    text: "Демо"
  },
  {
    id: 1,
    value: TariffType.PlatinumResident,
    text: "Платиновый резидент"
  },
  {
    id: 1,
    value: TariffType.Custom,
    text: "Индивидуальные услови"
  },
]