import React from "react";
import { useTranslation } from "react-i18next";
import { CompanyForAdminDto, CompanyModuleTypeDto } from "../../../../api";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, Dialog, DialogActions, Input, InputNumber, Select, Switch } from "../../../uiKit";
import { TARIFF_LIST } from "../../../../constants/TariffList";

interface ICreateEditCompanyDialogView {
  open: boolean;
  isLoading: boolean;
  companyId?: number | null;
  companyModuleTypeArray: CompanyModuleTypeDto[];
  companyModuleTypeArrayActive: number[];
  settingsState: CompanyForAdminDto;

  setSettingsState: (state: CompanyForAdminDto) => void;
  handleModuleTypeChange: (key: number, value: boolean) => void;
  onSave: () => void;
  onCreate: () => void;
  onClose: () => void;
}

function CreateEditCompanyDialogView(props: ICreateEditCompanyDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("parse:company_settings")}
      open={props.open}
      onClose={props.onClose}
      scrollStrategy="dialogBody"
      closable={false}
    >
      <div className="d-stack-column spacing-3">
        <div>
          <SubheaderText text={t("parse:external_id")} />
          <Input
            placeholder={t("parse:external_id")}
            value={props.settingsState.externalId ?? ""}
            onInput={(event) =>
              props.setSettingsState({ ...props.settingsState, externalId: event.currentTarget.value })
            }
          />
        </div>
        <div>
          <SubheaderText text={t("ui:subheader.contact_person")} />
          <Input
            placeholder={t("ui:subheader.contact_person")}
            value={props.settingsState.contactName ?? ""}
            onInput={(event) =>
              props.setSettingsState({ ...props.settingsState, contactName: event.currentTarget.value })
            }
          />
        </div>
        <div>
          <SubheaderText text={t("ui:subheader.contact_phone_number")} />
          <Input
            placeholder={t("ui:placeholder.contact_phone_number")}
            value={props.settingsState.contactPhoneNumber ?? ""}
            onInput={(event) =>
              props.setSettingsState({ ...props.settingsState, contactPhoneNumber: event.currentTarget.value })
            }
          />
        </div>
        {/*<div>*/}
        {/*  <SubheaderText text={t("ui:subheader.external_id")} />*/}
        {/*  <Input*/}
        {/*    placeholder={t("ui:placeholder.external_id")}*/}
        {/*    // value={props.settingsState. ?? ""}*/}
        {/*    onInput={(event) =>*/}
        {/*      props.setSettingsState({ ...props.settingsState, contactPhoneNumber: event.currentTarget.value })*/}
        {/*    }*/}
        {/*  />*/}
        {/*</div>*/}
        <div>
          <SubheaderText text={t("parse:ui_type")} />
          <Select
            disabled={!props.companyId}
            className="full-width"
            value={props.settingsState.uiType}
            onChange={(value) => props.setSettingsState({ ...props.settingsState, uiType: value })}
            items={[
              { id: 1, text: t("parse:ui_type_old"), value: 1 },
              { id: 2, text: t("parse:ui_type_new"), value: 2 },
            ]}
          />
        </div>
        <div>
          <SubheaderText text={t("parse:tariff")} />
          <Select
            className="full-width"
            value={props.settingsState.tariffCode}
            onChange={(value) => props.setSettingsState({ ...props.settingsState, tariffCode: value })}
            items={TARIFF_LIST}
          />
        </div>
        <div>
          <SubheaderText text={t("parse:user_limit")} />
          <InputNumber
            className="full-width"
            placeholder={"∞"}
            min={0}
            value={props.settingsState.userLimit ?? ""}
            onChange={(value) =>
              props.setSettingsState({ ...props.settingsState, userLimit: Number(value) == 0 ? null : Number(value) })
            }
          />
        </div>
        <div className="d-stack-column spacing-2">
          <Switch
            onChange={(value) => props.setSettingsState({
              ...props.settingsState,
              isPaid: value,
              isPartiallyPaid: false,
            })}
            checked={!!props.settingsState.isPaid}
            label={t("ui:label.paid_company")}
          />
          <Switch
            onChange={(value) => props.setSettingsState({
              ...props.settingsState,
              isPartiallyPaid: value,
              isPaid: false,
            })}
            checked={!!props.settingsState.isPartiallyPaid}
            label={t("ui:label.partially_paid_company")}
          />
          <Switch
            onChange={(value) => props.setSettingsState({ ...props.settingsState, isTest: value })}
            checked={!!props.settingsState.isTest}
            label={t("ui:label.test_company")}
          />
          <Switch
            onChange={(value) => props.setSettingsState({ ...props.settingsState, isInactive: value })}
            checked={!!props.settingsState.isInactive}
            label={t("ui:label.inactive")}
          />
        </div>
        <div className="d-stack-column spacing-2">
          <SubheaderText text={t("parse:available_modules")} />
          {props.companyModuleTypeArray
            // TODO: Remove later
            .sort((a, b) => (a?.key ?? 0) - (b?.key ?? 0))
            .map((mt) => (
              <Switch
                key={mt.key}
                disabled={
                  props.isLoading
                  // ((mt.key == 2 || mt.key == 7 || mt.key == 10) &&
                  //   !props.companyModuleTypeArrayActive.some((ta) => ta == mt.key))
                }
                onChange={(value) => props.handleModuleTypeChange(mt.key as number, value)}
                checked={props.companyModuleTypeArrayActive.some((ta) => ta == mt.key)}
                label={mt.name}
              />
            ))}
        </div>
      </div>
      <DialogActions>
        <Button disabled={props.isLoading} variant="text" onClick={props.onClose}>
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={props.isLoading}
          variant="filled"
          onClick={() => (props.companyId != null ? props.onSave() : props.onCreate())}
        >
          {props.companyId != null ? t("ui:button.save") : t("ui:button.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateEditCompanyDialogView;
